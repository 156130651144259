<template>
  <div class="process-details-content">
    <loading :active.sync="isLoading"
             :can-cancel="false"
             :is-full-page="fullPage"></loading>
    <div class="container">
      <div class="row full-heigth">

        <!-- Inicio timeline-->
        <div class="col-md-12">
          <div class="close-button mb-4">
            <div class="btn btn-link buttonClose d-inline-block" @click="onClose()">
              <span><i class="text-primary rc-Ativo-36"> </i></span>
            </div>
          </div>
          <div>
            <div class="row pt-5 pb-4">
                <!-- Table -->
                <!--<div v-on:click="toggleColumn">Config</div>-->
                <div class="table-responsive ">
                  <vuetable
                            ref="vuetable"
                            :noDataTemplate="''"
                            :api-url="taskLogEndpoint"
                            :fields="fieldsTable"
                            :sort-order="sortOrder"
                            :css="css.table"
                            pagination-path="pagination"
                            :per-page="20"
                            :multi-sort="multiSort"
                            :append-params="filter"
                            multi-sort-key="shift"
                            :http-options="httpOptions"
                            track-by="id_process"
                            @vuetable:pagination-data="onPaginationData"
                            @vuetable:loading="onLoading"
                            @vuetable:loaded="onLoaded"
                            @vuetable:load-error="handleLoadError"
                            @vuetable:cell-clicked="onRowClicked">

                  </vuetable>

                </div>
                <vuetable-pagination ref="pagination"
                                     :css="css.pagination"
                                     @vuetable-pagination:change-page="onChangePage"
                ></vuetable-pagination>
                <!-- END Table -->
            </div>
          <div>
          </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
// Components
import Vuetable, { VuetablePagination } from 'vuetable-2'
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'

export default {
  name: 'PersonInfoView',
  components: {
    Vuetable,
    VuetablePagination,
    Loading
  },
  data () {
    return {
      isLoading: false,
      fullPage: true,
      filter: {},
      task: {
        title: null
      },
      taskLogEndpoint: null,
      multiSort: true,
      fieldsTable: [
        {
          name: 'contact_name',
          title: global.instanceApp.$i18n.t('form.taskSend.contact_name'),
          sortField: 'contact_name',
          dataClass: 'text-left',
          titleClass: 'text-left',
          formatter: (value) => this.$util.formatEmpty(value)
        },
        {
          name: 'contact_email',
          title: global.instanceApp.$i18n.t('form.taskSend.contact_email'),
          sortField: 'contact_email',
          dataClass: 'text-left',
          titleClass: 'text-left',
          formatter: (value) => this.$util.formatEmpty(value)
        },
        {
          name: 'customer_name',
          title: global.instanceApp.$i18n.t('form.taskSend.customer_name'),
          sortField: 'customer_name',
          dataClass: 'text-left',
          titleClass: 'text-left',
          formatter: (value) => this.$util.formatEmpty(value)
        },
        {
          name: 'send_date',
          title: global.instanceApp.$i18n.t('form.taskSend.send_date'),
          sortField: 'send_date',
          formatter: (value) => this.formatDateTime(value)
        },
        {
          name: 'delivery_date',
          title: global.instanceApp.$i18n.t('form.taskSend.delivery_date'),
          sortField: 'delivery_date',
          formatter: (value) => this.formatDateTime(value)
        },
        {
          name: 'opened_date',
          title: global.instanceApp.$i18n.t('form.taskSend.opened_date'),
          sortField: 'opened_date ',
          formatter: (value) => this.formatDateTime(value)
        },
        {
          name: 'status',
          title: global.instanceApp.$i18n.t('form.task.status.title'),
          sortField: 'status',
          dataClass: 'text-center',
          titleClass: 'text-center',
          formatter: (value) => this.formatStatus(value)
        }

      ],
      sortOrder: [
        { field: 'tr.id', direction: 'desc' }
      ],
      httpOptions: {
        headers: {
          'Authorization': 'Bearer ' + global.instanceApp.$Cookie.get('_userAuth_')
        }
      },
      css: {
        table: {
          tableClass: 'table table-hover align-middle table-clickable',
          rowCss: 'teste',
          loadingClass: 'loading',
          ascendingIcon: 'ti-angle-double-up',
          descendingIcon: 'ti-angle-double-down',
          handleIcon: 'glyphicon glyphicon-menu-hamburger'
        },
        pagination: {
          infoClass: 'pull-left',
          wrapperClass: 'vuetable-pagination text-center',
          activeClass: 'page-item active',
          disabledClass: 'disabled',
          pageClass: 'btn btn-border',
          linkClass: 'btn btn-border',
          icons: {
            first: '',
            prev: '',
            next: '',
            last: ''
          }
        }
      }
    }
  },
  created () {
    let taskrunId = this.taskrunId

    this.taskLogEndpoint = process.env.VUE_APP_APIENDPOINT + 'v2/task/' + taskrunId + '/campaign/log/send'
  },
  methods: {
    onClose () {
      global.instanceApp.$alertSwal.close()
    },
    formatDateTime (value, locale, emptyValue) {
      emptyValue = emptyValue || null

      if (!value) {
        return emptyValue
      }

      locale = locale || 'pt-BR'

      let options = {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit'
      }

      if (!value) {
        return null
      }

      let date = new Date(value)

      if (locale === 'pt-BR') {
        options.timeZone = 'America/Sao_Paulo'

        return date.toLocaleString('pt-BR', options)
      } else {
        options.timeZone = 'America/New_York'

        return date.toLocaleString('en-US', options)
      }
    },
    formatStatus (value) {
      if (value === undefined || value === null) {
        return ' - '
      }

      let statusList = [
        {
          'id': 0,
          'label': global.instanceApp.$i18n.t('form.task.status.pending'),
          'color': '#f1ba26'
        },
        {
          'id': 1,
          'label': global.instanceApp.$i18n.t('form.task.status.pending'),
          'color': '#f1ba26'
        },
        {
          'id': 2,
          'label': global.instanceApp.$i18n.t('form.task.status.complete'),
          'color': '#5ec65f'
        },
        {
          'id': 3,
          'label': global.instanceApp.$i18n.t('form.task.status.delivered'),
          'color': '#5ec65f'
        },
        {
          'id': 4,
          'label': global.instanceApp.$i18n.t('form.task.status.error'),
          'color': '#F3614E'
        },
        {
          'id': 5,
          'label': global.instanceApp.$i18n.t('form.task.status.bounced'),
          'color': '#F3614E'
        },
        {
          'id': 6,
          'label': global.instanceApp.$i18n.t('form.task.status.partial_delivered'),
          'color': '#f1ba26'
        },
        {
          'id': 7,
          'label': global.instanceApp.$i18n.t('form.task.status.opened'),
          'color': '#5ec65f'
        }
      ]

      let status = statusList.find(obj => {
        if (obj.id === value) {
          return obj
        }
      })

      return '<span style="color: ' + status.color + '">' + status.label + '</span>'
    },
    handleLoadError (response) {
    },
    onPaginationData (paginationData) {
      this.$refs.pagination.setPaginationData(paginationData)
    },
    onChangePage (page) {
      this.$refs.vuetable.changePage(page)
    },
    onLoading () {
      this.isLoading = true
    },
    onLoaded () {
      this.isLoading = false
      this.$jQuery('table.table').reflowTable()
    },
    onRowClicked (row) {
    }
  }
}
</script>

<style scoped>

  .close-button {
    position: absolute;
    top: 15px;
    right: 33px;
  }

  tr.internal {
    background-color: #fcf8e3;
  }

</style>
